<template>
  <div class="helpCenter-page">
    <div class="banner">
      <div class="w-auto">
        <h2 class="title">帮助与文档</h2>
        <p class="content">
          海星区块链BaaS平台将给您提供全面的应用帮助和技术支持。
        </p>
      </div>
    </div>
    <div class="fingerpost w-auto">
      <!-- <dl class="IntroductionGroup">
        <dt @click="routerPush('/document/description')">产品简介</dt>
        <dd
          v-for="item in group1"
          :key="item.id"
          @click="routerPush(item.path)"
        >
          {{ item.name }}
        </dd>
      </dl>
      <dl class="IntroductionGroup">
        <dt @click="routerPush('/document/loginAndRegister')">操作指南</dt>
        <dd
          v-for="item in group2"
          :key="item.id"
          @click="routerPush(item.path)"
        >
          {{ item.name }}
        </dd>
      </dl> -->
      <dl class="IntroductionGroup">
        <dt @click="routerPush('/document/quickStart')">开发指南</dt>
        <dd
          v-for="item in group3"
          :key="item.id"
          @click="routerPush(item.path)"
        >
          {{ item.name }}
        </dd>
      </dl>
      <!-- <dl class="IntroductionGroup">
        <dt @click="routerPush('/document/commonProblem')">常见问题</dt>
        <dd
          v-for="item in group4"
          :key="item.id"
          @click="routerPush(item.path)"
        >
          {{ item.name }}
        </dd>
      </dl> -->
    </div>
  </div>
</template>

<script setup>
export default {
  data() {
    return {
      group1: [
        { id: 1, name: "产品简介", path: "/document/description" },
        { id: 2, name: "产品优势", path: "/document/advantage" },
        { id: 3, name: "应用场景", path: "/document/scene" },
        { id: 4, name: "技术术语", path: "/document/term" },
        { id: 5, name: "发布说明", path: "/document/releaseNotes" },
      ],
      group2: [
        { id: 1, name: "登录注册", path: "/document/loginAndRegister" },
        { id: 2, name: "身份认证", path: "/document/idAuthentication" },
        { id: 3, name: "存证中心", path: "/document/saveCenter" },
        { id: 4, name: "溯源中心", path: "/document/resourceCenter" },
        { id: 5, name: "应用中心", path: "/document/appCenter" },
      ],
      group3: [
        // { id: 1, name: "快速开始", path: "/document/quickStart" },
        { id: 2, name: "接入指导", path: "/document/accessGuide" },
        { id: 3, name: "API概览", path: "/document/apiConcept" },
        { id: 4, name: "存证中心", path: "/document/depositCertificate" },
        { id: 5, name: "溯源中心", path: "/document/trace" },
      ],
      group4: [{ id: 1, name: "常见问题", path: "/document/commonProblem" }],
    };
  },
  methods: {
    routerPush(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.helpCenter-page {
  .banner {
    height: 360px;
    background-image: url("../../assets/img/helpCenter/帮助与文档_banner.png");
    background-repeat: no-repeat;
    background-position: center center;
    padding: 20px;
    .title {
      color: #fff;
      margin-top: 35px;
      margin-bottom: 15px;
      font-size: 42px;
      font-weight: 400;
    }
    .content {
      color: #fff;
      font-size: 16px;
    }
  }
  .fingerpost {
    padding: 80px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .IntroductionGroup {
      width: 205px;
      margin: 30px 20px;
      dt {
        font-size: 24px;
        color: #333333;
        margin-bottom: 30px;
        cursor: pointer;
        &:hover {
          color: #387efd;
        }
      }
      dd {
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        color: #777a8c;
        border-bottom: 1px solid #ececec;
        cursor: pointer;
        &:hover {
          color: #387efd;
        }
      }
    }
  }
}
</style>